<template>
  <body id="kt_body" class="bg-body">
    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
      <!--begin::Authentication - Sign-in -->
      <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <!--begin::Aside-->
        <div
          class="
            d-flex
            flex-column flex-lg-row-auto
            w-xl-600px
            positon-xl-relative
          "
          style="background-color: #f3f3f3"
        >
          <!--begin::Wrapper-->
          <div
            class="
              d-flex
              flex-column
              position-xl-fixed
              top-0
              bottom-0
              w-xl-600px
              scroll-y
            "
          >
            <!--begin::Content-->
            <div
              class="
                d-flex
                flex-row-fluid flex-column
                text-center
                p-10
                pt-lg-20
              "
            >
              <!--begin::Logo-->
              <div class="mb-20">
                <img
                  alt="Logo"
                  src="media/logos/Logo-SpacesGoPortal.svg"
                  style="height: 120px"
                />
              </div>

              <h3
                class="font-weight-bolder text-center fs-1 fs-4-lg"
                style="color: #5b5b5b"
              >
                <div>效率的營運</div>
                <div class="mt-2">優質的服務</div>
                <div class="mt-2">智慧空間體驗</div>
              </h3>
            </div>
            <!--end::Content-->
            <!--begin::Illustration-->
            <div
              class="
                d-flex
                flex-row-auto
                bgi-no-repeat
                bgi-position-x-center
                bgi-size-contain
                bgi-position-y-bottom
                min-h-300px min-h-lg-450px
              "
              style="
                background-image: url('media/illustrations/login/login-visual_spaceGo.svg');
              "
            ></div>
            <!--end::Illustration-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Aside-->
        <!--begin::Body-->
        <div class="d-flex flex-column flex-lg-row-fluid pt-10 pb-3">
          <!--begin::Content-->
          <div class="d-flex flex-center flex-column flex-column-fluid">
            <!--begin::Wrapper-->
            <div class="w-lg-500px p-10 p-lg-15 mx-auto">
              <!--begin::Form-->
              <Form
                class="form w-100"
                id="kt_login_signin_form"
                @submit="onSubmitLogin"
                :validation-schema="login"
              >
                <!--begin::Heading-->
                <div class="text-start mb-10">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-3">歡迎來到 Spaces Go Portal</h1>
                  <!--end::Title-->
                </div>
                <!--begin::Heading-->

                <!--begin::Input group-->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label fs-6 fw-bolder text-dark"
                    >帳號 Email</label
                  >
                  <!--end::Label-->

                  <!--begin::Input-->
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    name="email"
                    autocomplete="off"
                  />
                  <!--end::Input-->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="email">
                        <p>請輸入帳號(電子信箱)</p>
                     
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="fv-row mb-10">
                  <!--begin::Wrapper-->
                  <div class="d-flex flex-stack mb-2">
                    <!--begin::Label-->
                    <label class="form-label fw-bolder text-dark fs-6 mb-0"
                      >密碼 Password</label
                    >
                    <!--end::Label-->
                  </div>
                  <!--end::Wrapper-->

                  <!--begin::Input-->
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    autocomplete="off"
                  />
                  <!--end::Input-->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="password">
                        <p>請輸入密碼</p>
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Actions-->
                <div class="text-center">
                  <!--begin::Submit button-->
                  <button
                    type="submit"
                    ref="submitButton"
                    id="kt_sign_in_submit"
                    class="btn btn-lg btn-primary w-100 mb-5"
                  >
                    <span class="indicator-label"> 登入 </span>

                    <span class="indicator-progress">
                      Please wait...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                  <!--end::Submit button-->
                </div>
                <!--end::Actions-->
              </Form>
              <!--end::Form-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Content-->
          <!--begin::Footer-->
          <div
            class="
              d-flex
              flex-center
              d-flex
              justify-content-end
              flex-wrap
              fs-6
              p-5
              pb-0
            "
          >
            <!--begin::Links-->
            <div class="d-flex flex-center fw-bold fs-6">
              <span class="text-muted font-weight-bold me-3"
                >© 2021 Viainno</span
              >
              <a
                href="https://www.viainno.com/_HomeGo_Privacy.php"
                class="privacyPolicy"
                >隱私權政策</a
              >
            </div>
            <!--end::Links-->
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
  </body>

  <!-- 選擇營運商modal -->
  <!-- <div
    class="modal fade"
    id="chooseOperatorModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">選擇營運商</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ChooseOperatorModal
            :operator="operator"
            @closeModal="closeModal()"
          />
        </div>
      </div>
    </div>
  </div> -->
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { Modal } from "bootstrap";
// import { getOperatorBySid } from "@/core/services/api/operator";
import { defineComponent, reactive, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import ChooseOperatorModal from "./component/ChooseOperatorModal.vue";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    // ChooseOperatorModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    let operator = reactive<any[]>([]);
    let myModal;
    // 根據使用者查詢營運商
    // const getOperator = async (): Promise<any> => {
    //   return await getOperatorBySid();
    // };

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });
    const closeModal = () => {
      myModal.hide();
    };
    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "再試一次!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      operator,
      closeModal,
    };
  },
});
</script>


<style scoped>
.privacyPolicy {
  color: #b5b5c3;
}

.privacyPolicy:hover {
  cursor: pointer;
  color: #249cad;
}
</style>