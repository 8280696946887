
import { Modal } from "bootstrap";
// import { getOperatorBySid } from "@/core/services/api/operator";
import { defineComponent, reactive, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import ChooseOperatorModal from "./component/ChooseOperatorModal.vue";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    // ChooseOperatorModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    let operator = reactive<any[]>([]);
    let myModal;
    // 根據使用者查詢營運商
    // const getOperator = async (): Promise<any> => {
    //   return await getOperatorBySid();
    // };

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });
    const closeModal = () => {
      myModal.hide();
    };
    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "再試一次!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      operator,
      closeModal,
    };
  },
});
